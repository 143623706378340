<template>
    <BaseContainer
        id="ai"
        as="section"
        class="flex flex-col items-center"
        :aria-label="t('title')"
    >
        <h2 class="text-center text-4xl font-bold leading-10">
            {{ t('title') }}
        </h2>

        <p class="mt-4 text-center text-xl leading-7">{{ t('subtitle') }}</p>

        <div
            class="mt-20 flex w-full flex-col items-center gap-20 overflow-hidden lg:flex-row lg:items-start lg:gap-10"
        >
            <!-- Items -->
            <ul
                class="order-2 flex shrink-0 flex-col gap-2 lg:order-1 lg:w-[340px]"
            >
                <li
                    v-for="item in items"
                    :key="item.description"
                    class="flex flex-col items-center justify-center gap-2.5 px-4 py-4 lg:items-start"
                >
                    <img
                        :src="item.icon"
                        height="48"
                        width="48"
                        class="h-12 w-12"
                        :alt="`${item.description} icon`"
                    />
                    <p class="font-xl text-center leading-7 md:text-start">
                        {{ item.description }}
                    </p>
                </li>
            </ul>

            <!-- Video -->
            <video
                src="/videos/ai_demo.mp4"
                muted
                controls
                loop
                class="order-1 h-full w-full self-center overflow-hidden rounded-xl lg:order-2"
            />
        </div>
    </BaseContainer>
</template>

<script setup lang="ts">
const { t } = useI18n()

const items = [
    { description: t('items.1'), icon: '/icons/eye.svg' },
    { description: t('items.2'), icon: '/icons/formats.svg' },
    { description: t('items.3'), icon: '/icons/wand.svg' },
]
</script>

<i18n lang="yaml">
en:
    title: 'Meet your AI assistant'
    subtitle: 'Forget manual input and save valuable time and effort - all you need to do is upload a PDF file or a photo of the expense document, and we will take care of the rest.'
    items:
        1: 'Recognizes, extracts, and fills data in seconds'
        2: 'Supports various document formats and languages'
        3: 'Reduces the risk of human errors'
bg:
    title: 'Представяме ви вашия AI помощник'
    subtitle: 'Забравете за ръчното въвеждане и спестете ценно време и усилия - всичко, което трябва да направите, е да качите PDF файл или снимка на разходния документ, а ние ще се погрижим за останалото.'
    items:
        1: 'Разпознава, извлича и попълва данни за секунди'
        2: 'Поддържа различни формати и езици на документи'
        3: 'Намалява риска от човешки грешки'
</i18n>
