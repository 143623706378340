<template>
    <section id="about-us" class="relative" :aria-label="t('title')">
        <!-- Gradient -->
        <div
            class="pointer-events-none absolute inset-0 bg-gradient-to-b from-blue-600/10 from-0% to-white to-50%"
        />

        <BaseContainer class="relative flex flex-col">
            <h3 class="mb-4 text-center text-4xl font-bold leading-10">
                {{ t('title') }}
            </h3>

            <p class="text-center text-xl leading-7">{{ t('subheader') }}</p>

            <div class="mt-10 flex flex-col gap-8 md:mt-20">
                <BaseExpandable
                    v-for="card in cards"
                    :key="card.title"
                    v-model="card.isOpen"
                    :title="card.title"
                    :description="card.description"
                />
            </div>
        </BaseContainer>
    </section>
</template>

<script setup lang="ts">
const { t } = useI18n()

const cards = ref([
    {
        title: t('who-are-we'),
        description: t('who-are-we-text'),
        isOpen: true,
    },
    {
        title: t('feature-plans'),
        description: t('feature-plans-text'),
        isOpen: false,
    },
])
</script>

<i18n lang="yaml">
bg:
    title: 'За нас'
    subheader: 'Научи повече за нас, нашата мисия и амбиции'
    who-are-we: 'Кои сме ние?'
    who-are-we-text: 'Ние сме мотивирани млади хора, обединени от идеята да създадат продукт, който да спомага малките и средни предприятия като даде прозрачност и информираност на мениджмънта и същевременно оптимизира процеса по взаимодействие със счетоводството. В екипа си имаме хора със стабилен счетоводен опит, което гарантира коректна работа от страна на законови изисквания и познаване на нуждите в сферата, докато друга част от екипа се грижи за безпроблемното функциониране на платформата и сигурността на Вашите данни.'
    feature-plans: 'Какви са бъдещите планове за eFirma?'
    feature-plans-text: 'Плановете ни за бъдещето включват разширяване на функционалностите на платформата с нови модули и интеграции с други софтуерни продукти. Ще продължим да подобряваме възможностите за автоматизация, за да улесним управлението на бизнес процесите. Сред следващите ни стъпки са внедряването на AI за четене на разходни документи и банкови интеграции за свързване на сметки и достъп до банкови извлечения.'
en:
    title: 'About Us'
    subheader: 'Learn more about us, our mission and ambitions'
    who-are-we: 'Who are we?'
    who-are-we-text: 'We are motivated young people united by the idea of creating a product that helps small and medium-sized businesses by providing transparency and information to management and at the same time optimizing the interaction process with accounting. In our team we have people with stable accounting experience, which guarantees correct work in terms of legal requirements and knowledge of the needs in the field, while another part of the team takes care of the smooth functioning of the platform and the security of your data.'
    feature-plans: 'What are the future plans for eFirma?'
    feature-plans-text: 'Our future plans include expanding the platform’s functionalities with new modules and integrations with other software products. We will continue to enhance automation features to simplify business process management. Our next steps include implementing AI for reading expense documents and bank integration for linking accounts and accessing bank statements.'
</i18n>
