<template>
    <div
        class="flex w-full flex-wrap items-center justify-center px-10 pt-10 text-center text-sm md:text-base"
    >
        <SparklesIcon class="mr-2 h-4 shrink-0 text-yellow-400" />

        <b class="mr-1 text-slate-600">{{ EFIRMA_AI }}</b>

        <i18n-t keypath="title" tag="span" class="text-slate-600">
            <template #autoFillData>
                <b>{{ t('auto-fill-data') }}</b>
            </template>
        </i18n-t>

        <BaseButton
            to="#ai"
            color="slate"
            class="group ml-4 hidden h-[26px] text-xs md:flex"
        >
            {{ t('learn-more') }}
            <ChevronRightIcon
                class="ml-1 h-4 text-white transition-transform group-hover:translate-x-1"
            />
        </BaseButton>

        <NuxtLink
            to="#ai"
            class="w-full text-xs font-semibold text-blue-600 underline md:hidden"
        >
            {{ t('learn-more') }}
        </NuxtLink>
    </div>
</template>

<script setup lang="ts">
import { SparklesIcon, ChevronRightIcon } from '@heroicons/vue/24/solid'

const EFIRMA_AI = 'eFirma AI'

const { t } = useI18n()
</script>

<i18n lang="yaml">
en:
    title: '- Your assistant for {autoFillData}'
    auto-fill-data: 'auto-filling data'
    learn-more: 'Learn more'
bg:
    title: '- Вашият помощник за {autoFillData}'
    auto-fill-data: 'автоматично попълване на данни'
    learn-more: 'Научи повече'
</i18n>
