<template>
    <AiBanner />

    <SectionHero />

    <SectionSharedSpace />

    <SectionPrimaryFeatures />

    <SectionAI />

    <SectionPlans />

    <SectionAboutUs />

    <SectionContactUs />
</template>

<script setup lang="ts">
const { t } = useI18n()

useSeoMeta({
    title: () => t('meta.title'),
    description: () => t('meta.description'),
})
</script>

<i18n lang="yaml">
en:
    meta:
        title: 'Simplified accounting for your business'
        description: 'Optimize your operations with eFirma, your comprehensive business management solution. Manage invoices, expenses, contacts, and much more with ease.'

bg:
    meta:
        title: 'Улеснено счетоводство за Вашия бизнес'
        description: 'Оптимизирайте вашите операции с eFirma, вашето всеобхватно решение за управление на бизнеса. Управлявайте фактури, разходи, контакти и още много други с лекота.'
</i18n>
